<template>
  <div>
    <b-row v-if="step === 1">
      <b-col lg="6" order="1" order-md="0">
        <div class="panel-form my-3 my-md-5">
          <p class="title text-center">วางแผนเกษียณ</p>
          <InputRang
            textFloat="ปัจจุบันอายุ"
            type="text"
            id="age"
            name="age"
            :min="form.age.min"
            :max="form.age.max"
            :step="form.age.scale"
            v-model="form.age.value"
            :isValidate="$v.form.age.value.$error"
            :v="$v.form.age.value"
            xtextGuide="ลองเกษียณอายุข้าลงไหม?"
          />
          <InputRang
            textFloat="เงินออมปัจจุบัน"
            type="text"
            id="saving"
            name="saving"
            :min="form.saving.min"
            :max="form.saving.max"
            :step="form.saving.scale"
            v-model="form.saving.value"
            :isValidate="$v.form.saving.value.$error"
            :v="$v.form.saving.value"
          />
          <InputRang
            textFloat="ออมเดือนละกี่บาท"
            type="text"
            id="savingPerMonth"
            name="savingPerMonth"
            :min="form.savingPerMonth.min"
            :max="form.savingPerMonth.max"
            :step="form.savingPerMonth.scale"
            v-model="form.savingPerMonth.value"
            :isValidate="$v.form.savingPerMonth.value.$error"
            :v="$v.form.savingPerMonth.value"
            :textGuide="isTestAgain && !formResponse.status ? 'ลองออมเงินเพิ่มดูสิ' : ''"
          />
          <InputRangPercent
            textFloat="อัตราเงินเฟ้อ % ต่อปี*"
            type="text"
            id="inflation"
            name="inflation"
            :min="form.inflation.min"
            :max="form.inflation.max"
            :step="form.inflation.scale"
            v-model="form.inflation.value"
            :isValidate="$v.form.inflation.value.$error"
            :v="$v.form.inflation.value"
          />
          <InputRangPercent
            textFloat="อัตราผลตอบแทนเงินฝาก**"
            type="text"
            id="depositReturn"
            name="depositReturn"
            :min="form.depositReturn.min"
            :max="form.depositReturn.max"
            :step="form.depositReturn.scale"
            v-model="form.depositReturn.value"
            :isValidate="$v.form.depositReturn.value.$error"
            :v="$v.form.depositReturn.value"
            :textGuide="isTestAgain && !formResponse.status ? 'ลองลงทุนที่ให้ผลตอบแทนเพิ่มขึ้น' : ''"
          />
          <InputRang
            textFloat="อายุที่คาดว่าจะเกษียณ (ปี)"
            type="text"
            id="expectRetireAge"
            name="expectRetireAge"
            :min="form.expectRetireAge.min"
            :max="form.expectRetireAge.max"
            :step="form.expectRetireAge.scale"
            v-model="form.expectRetireAge.value"
            :isValidate="$v.form.expectRetireAge.value.$error"
            :v="$v.form.expectRetireAge.value"
          />
          <InputRang
            textFloat="อยากใช้เงินหลังเกษียณจนถึงอายุเท่าไหร่? (ปี)"
            type="text"
            id="expectSpendAge"
            name="expectSpendAge"
            :min="form.expectSpendAge.min"
            :max="form.expectSpendAge.max"
            :step="form.expectSpendAge.scale"
            v-model="form.expectSpendAge.value"
            :isValidate="$v.form.expectSpendAge.value.$error"
            :v="$v.form.expectSpendAge.value"
          />
          <div class="mt-3">
            <InputRangWantTotal
              textFloat="เงินที่ต้องการใช้ต่อเดือนหลังเกษียณ?"
              type="text"
              id="expectSpendPerMonth"
              name="expectSpendPerMonth"
              :min="form.expectSpendPerMonth.min"
              :max="form.expectSpendPerMonth.max"
              :step="form.expectSpendPerMonth.scale"
              v-model="form.expectSpendPerMonth.value"
              :isValidate="$v.form.expectSpendPerMonth.value.$error"
              :v="$v.form.expectSpendPerMonth.value"
              :textGuide="isTestAgain && !formResponse.status ? 'ลองลดค่าใช้จ่ายต่อเดือนหลังเกษียณไหม?' : ''"
            />
          </div>
          <div class="mt-3">
            <p class="desc">*อัตราเงินเฟ้อเฉลี่ย อยู่ที่ 3% ต่อปี</p>
            <p class="desc">**อัตราดอกเบี้ยเงินฝาก 1.5% ต่อปี</p>

            <b-button
              variant="main"
              type="button"
              block
              class="mt-5 mb-2"
              @click="checkForm"
              :disabled="btnDisabled"
            >
              ประมวลผล
            </b-button>
          </div>
        </div>
      </b-col>
      <b-col
        order="0"
        order-md="1"
        lg="6"
        class="d-flex align-items-center justify-content-center"
      >
        <img src="@/assets/images/Penguin-bg-test.png" class="w-100 mt-5 my-md-5" />
      </b-col>
    </b-row>

    <div v-else-if="step === 2">
      <ResultPositive v-if="formResponse.status" :formResponse="formResponse" @backStep="goToStep(1)" @confirmSubmit="confirmSubmit" />
      <ResultNegative v-else :formResponse="formResponse" @backStep="goToStep(1)" @confirmSubmit="confirmSubmit" />
    </div>
    <ModalLoading ref="modalLoading" :hasClose="false" />
    <ModalAlert
      ref="modalAlert"
      :text="modalMessage"
    />
    <ModalAlertError ref="modalAlertError" :text="modalMessageError" />
  </div>
</template>

<script>
import InputRang from "@/components/input/InputRang";
import InputRangPercent from "@/components/input/InputRangPercent";
import InputRangWantTotal from "@/components/input/InputRangWantTotal";
import ResultPositive from "./components/ResultPositive.vue";
import ResultNegative from "./components/ResultNegative.vue";
import {
  required,
  minValue,
  maxValue,
  numeric,
  decimal
} from "vuelidate/lib/validators";
import ModalLoading from "@/components/alert-modal/ModalLoading";
import ModalAlert from "@/components/alert-modal/ModalAlert";
import ModalAlertError from "@/components/alert-modal/ModalAlertError";

export default {
  components: {
    InputRang,
    InputRangPercent,
    InputRangWantTotal,
    ResultPositive,
    ResultNegative,
    ModalLoading,
    ModalAlert,
    ModalAlertError
  },
  data() {
    return {
      step: 1,
      loading: true,
      btnDisabled: false,
      lineProfile: {
        userId: "",
        displayName: "",
        pictureUrl: "",
        statusMessage: ""
      },
      modalMessage: "",
      modalMessageError: "",
      formSubmit: {
        userSessionId: "",
        Age: 0,
        Saving: 0,
        SavingPerMonth: 0,
        RetirementSpendingPerMonth: 0,
        RetirementAge: 0,
        SpendingUntilAge: 0,
        InflationRate: 0,
        IntereateRate: 0
      },
      form: {
        age: {
          value: 1,
          min: 1,
          max: 120,
          scale: 1
        },
        saving: {
          value: 0,
          min: 1,
          max: 5000000,
          scale: 1
        },
        savingPerMonth: {
          value: 0,
          min: 1,
          max: 5000000,
          scale: 1
        },
        inflation: {
          value: 0,
          min: 0,
          max: 100,
          scale: 0.5
        },
        depositReturn: {
          value: 0,
          min: 0,
          max: 100,
          scale: 0.5
        },
        expectRetireAge: {
          value: 0,
          min: 1,
          max: 120,
          scale: 1
        },
        expectSpendAge: {
          value: 0,
          min: 1,
          max: 120,
          scale: 1
        },
        expectSpendPerMonth: {
          value: 0,
          min: 0,
          max: 5000000,
          scale: 1
        }
      },
      formResponse: {
        status: false,
        text: "ไม่สำเร็จ",
        profit: 0,
        diffSavingAndPredict: 0,
        annualReturn: 0,
        saving: 0,
        moreToSaving: 0,
        retirementMoney: 0,
        yearToSaving: 0
      },
      isTestAgain: false,
      sessionId: ""
    };
  },
  validations() {
    return {
      form: {
        age: {
          value: {
            required,
            numeric,
            minValue: minValue(this.form.age.min),
            maxValue: maxValue(this.form.age.max),
            handleAgeValue: (value) => {
              return this.handleAgeValue(value);
            },
          }
        },
        saving: {
          value: {
            required,
            numeric,
            minValue: minValue(this.form.saving.min),
          }
        },
        savingPerMonth: {
          value: {
            required,
            numeric,
            minValue: minValue(this.form.savingPerMonth.min),
          }
        },
        inflation: {
          value: {
            required,
            decimal,
            minValue: minValue(this.form.inflation.min),
            maxValue: maxValue(this.form.inflation.max)
          }
        },
        depositReturn: {
          value: {
            required,
            decimal,
            minValue: minValue(this.form.depositReturn.min),
            maxValue: maxValue(this.form.depositReturn.max)
          }
        },
        expectRetireAge: {
          value: {
            required,
            numeric,
            maxValue: maxValue(this.form.expectRetireAge.max),
            handleExpectRetireAgeMin: (value) => {
              return this.handleExpectRetireAgeMin(value);
            },
            handleExpectRetireAgeValue: (value) => {
              return this.handleExpectRetireAgeValue(value);
            },
          }
        },
        expectSpendAge: {
          value: {
            required,
            numeric,
            maxValue: maxValue(this.form.expectSpendAge.max),
            handleExpectSpendAgeMin: (value) => {
              return this.handleExpectSpendAgeMin(value);
            },
            handleExpectSpendAgeValue: (value) => {
              return this.handleExpectSpendAgeValue(value);
            },
          }
        },
        expectSpendPerMonth: {
          value: {
            required,
            numeric,
            minValue: minValue(this.form.expectSpendPerMonth.min),
          }
        }
      }
    };
  },
  beforeCreate: async function () {
    if (this.$liff.isInClient()) {
      this.$liff
        .init({ liffId: this.$liff_Main_ID })
        .then(() => {
          this.$liff.getProfile().then(profile => {
            this.lineProfile = profile;
          });
        })
        .catch(error => {
          console.error("error", error);
        });
    }
  },
  created: async function () {
    this.sessionId = this.$route.query.sessionId;
    if(this.sessionId) await this.getUserData();
    else this.$router.push("/session-expired");
  },

  methods: {
    handleAgeValue(value) {
      return !(parseInt(value) > parseInt(this.form.expectRetireAge.value));
    },
    handleExpectRetireAgeValue(value) {
      return !(parseInt(value) > parseInt(this.form.expectSpendAge.value));
    },
    handleExpectRetireAgeMin(value) {
      return (parseInt(value) >= parseInt(this.form.expectRetireAge.min));
    },
    handleExpectSpendAgeValue(value) {
      return !(parseInt(value) < parseInt(this.form.expectRetireAge.value));
    },
    handleExpectSpendAgeMin(value) {
      return (parseInt(value) >= parseInt(this.form.expectSpendAge.min));
    },
    checkForm: async function () {
      this.$v.$touch();
      if (this.$v.$error) {
        return;
      }
      this.submit();
    },
    submit: async function () {
      this.formSubmit = {
        UserSessionId: this.sessionId,
        Age: this.form.age.value,
        Saving: this.form.saving.value,
        SavingPerMonth: this.form.savingPerMonth.value,
        RetirementSpendingPerMonth: this.form.expectSpendPerMonth.value,
        RetirementAge: this.form.expectRetireAge.value,
        SpendingUntilAge: this.form.expectSpendAge.value,
        InflationRate: this.form.inflation.value,
        IntereateRate: this.form.depositReturn.value
      };
      this.btnDisabled = true;
      this.$refs.modalLoading.show();
      await this.$axios
        .post(`${this.$baseUrl}/user/Saving/Calculate`, this.formSubmit)
        .then(async data => {
          if (data.data.result == 1) {
            await this.goToStep(2);
            this.formResponse = {...data.data.detail};
            this.isTestAgain = true
          } else {
            this.modalMessageError = data.data.detail[0];
            this.$refs.modalAlertError.show();
          }
          this.btnDisabled = false;
          this.$refs.modalLoading.hide();
        });
    },
    getUserData: async function () {
      await this.$store.commit("UPDATE_IS_LOADING_GLOBAL", true);
      await this.$axios
        .get(this.$baseUrl + "/form/Retirement/" + this.sessionId)
        .then(response => {
          if (response.data.result === 1) {
            let data = response.data.detail;
            this.form.age.value = data.age.value;
            this.form.depositReturn.value = data.depositReturn.value;
            this.form.expectRetireAge.value = data.expectRetireAge.value;
            this.form.expectSpendAge.value = data.expectSpendAge.value;
            this.form.inflation.value = data.inflation.value;
            this.form.saving.value = data.saving.value;
            this.form.savingPerMonth.value = data.savingPerMonth.value;
            this.form.expectSpendPerMonth.value = data.expectSpendPerMonth.value;
          } else {
            this.$router.push("/session-expired");
          }
        });
      await this.$store.commit("UPDATE_IS_LOADING_GLOBAL", false);
    },
    goToStep: async function (step) {
      await this.scrollToTop();
      this.step = step;
    },
    confirmSubmit: async function () {
      await this.submitResult();
      this.$v.$reset();
      this.isTestAgain = false;
      this.closeWindow();
    },
    submitResult: async function () {
      this.btnDisabled = true;
      this.$refs.modalLoading.show();

      await this.$axios
        .post(this.$baseUrl + "/User/Saving/Retirement/Message/" + this.sessionId, this.formSubmit)
        .then(response => {
          if (!response.data.result) {
            this.modalMessageError = response.data.detail;
            this.$refs.modalAlertError.show();
          }
        })
        .catch(error => {
          this.modalMessageError = error;
          this.$refs.modalAlertError.show();
        });

      this.btnDisabled = false;
      this.$refs.modalLoading.hide();
    },
    closeWindow() {
      console.log("closeWindow is worked windows");
      try {
        window.close();
      } catch (error) {
        console.log(error);
      }

      console.log("closeWindow is worked leff");
      try {
        this.$liff.closeWindow();
      } catch (error) {
        console.log(error);
      }
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    }
  }
};
</script>

<style lang="scss" scoped>
.text-error {
  color: #dc3545 !important;
  font-weight: bold;
  font-size: 16px;
}
@media (max-width: 767.98px) {
  .panel-form {
    padding: 10px;
    box-shadow: unset !important;
    border: 0;
  }
}
@media only screen and (min-width: 767.98px) {
  .text-error {
    font-size: 15px;
  }
}
</style>
