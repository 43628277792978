<template>
  <div>
    <b-row>
      <b-col lg="6" order="1">
        <div class="panel-form my-3 my-md-5 f-medium text-center">
          <p class="title text-success mb-3">แผนเกษียณของคุณสำเร็จ</p>
          <p>เงินออมทั้งหมด ณ วันเกษียณ (บาท)</p>
          <p class="f-size-38 main-color">
            {{ formResponse.profit | numeral("0,0.00") }}
          </p>
          <p class="mt-1">เป้าหมายการออมของคุณ</p>
          <p class="text-yello f-size-20">
            {{ formResponse.retirementMoney | numeral("0,0.00") }}
          </p>

          <div class="mt-5">
            <InputProgressResult
              :value="formResponse.retirementMoney"
              :max="formResponse.profit"
              result="positive"
            />
          </div>
          <p>สถานะแผนเกษียณที่วางไว้</p>
          <p class="text-green f-size-26 mt-1 mb-2">{{ formResponse.text }}</p>
          <p>ต้องออมเงินเพิ่มเติมอีก (บาท)</p>
          <p v-if="formResponse.diffSavingAndPredict >= 0">
            {{ formResponse.diffSavingAndPredict | numeral("0,0.00") }}
          </p>
          <p v-else>-</p>
        </div>
        <div>
          <p class="f-size-28 f-medium mb-0">แผนเกษียณสำเร็จ</p>
          <div class="div-card">
            <p class="f-size-22 main-color f-medium">
              ยินดีด้วย คุณมีเงินเพียงพอสำหรับใช้จ่ายตลอดอายุขัย
            </p>
            ณ วันเกษียณอายุ คุณจะมีเงินออมทั้งหมด
            <span class="main-color"
              >{{ formResponse.profit | numeral("0,0.00") }} บาท</span
            >
            และมีเงินเหลือต่อความมั่งคั่ง หรือมรดกให้ลูกหลาน
            <span class="text-green"
              >{{
                formResponse.diffSavingAndPredict*-1 | numeral("0,0.00")
              }}
              บาท</span
            >
          </div>
        </div>
      </b-col>
      <b-col lg="6" order="3">
        <div class="my-3 mb-md-5 text-center">
          <b-button
            variant="link-main"
            type="button"
            class="mb-3"
            @click="$emit('backStep')"
          >
            ปรับแผนเกษียณอีกครั้ง
          </b-button>
          <b-button
            variant="main"
            type="button"
            block
            @click="$emit('confirmSubmit')"
          >
            ยืนยันผลการวางแผน
          </b-button>
        </div>
      </b-col>
      <b-col
        lg="6"
        class="d-flex align-items-center justify-content-center"
        order="2"
      >
        <img
          src="@/assets/images/Success-3.png"
          class="mt-5 mt-md-0 penguin-success"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import InputProgressResult from "@/components/input/InputProgressResult";

export default {
  components: {
    InputProgressResult
  },
  props: {
    formResponse: {
      required: true,
      type: Object
    }
  }
};
</script>

<style lang="scss" scoped>
.panel-form {
  p {
    margin-bottom: 0;
  }
}
.div-card {
  background-color: #fff;
  width: 100%;
  padding: 15px;
  border-radius: 20px;
  border: 3px solid #ff6439;
  box-shadow: 0px 3px 30px #00000021;
}
img.penguin-success {
  width: 80% !important;
}
@media (max-width: 767.98px) {
  .panel-form {
    margin: 30px 0;
    padding: 15px;
    box-shadow: unset !important;
    border: 0;
  }
  img.penguin-success {
    width: 100% !important;
  }
}
</style>
