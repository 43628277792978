<template>
  <div :class="['div-input', className]">
    <div class="display-only" v-if="isDisplay"></div>
    <div :class="['input-custom', { error: isValidate }]">
      <label v-if="textFloat" class="mt-5 text-wrap">
        {{ textFloat }}
        <span v-if="isRequired" class="text-danger">*</span>
      </label>
      <p v-if="textGuide" class="text-guide">{{ textGuide }}</p>
      <div class="mt-3">
        <div class="d-flex justify-content-center">
          <input
            :class="['custom-input']"
            :name="name"
            :required="required"
            :value="val"
            size="sm"
            @input="handleInput($event.target.value)"
            @keyup="isNumber"
            @keypress="isNumber"
            :disabled="disabled"
          />
        </div>
        <div class="d-flex flex-column align-items-center">
          <img src="@/assets/images/PenguinBar.png" class="img-bar" />
          <div class="wrap-progress">
            <input
              :class="['progress']"
              type="range"
              :name="name"
              :id="id"
              :value="value || 0"
              :disabled="disabled"
              :min="min"
              :max="max"
              :step="step"
              @input="handleInput($event.target.value)"
              :style="background"
            />
          </div>
        </div>
      </div>
    </div>
    <span v-if="detail" class="text-desc text-wrap">{{ detail }}</span>
    <div v-if="v && v.$error" class="text-right">
      <span class="text-error" v-if="v.required == false">{{
        $t("msgValidateRequired")
      }}</span>
      <span class="text-error" v-else-if="v.integer == false">{{
        $t("msgValidateInteger")
      }}</span>

      <span class="text-error" v-else-if="v.decimal == false">{{
        $t("msgValidateInteger")
      }}</span>
      <span class="text-error" v-else-if="v.minValue == false"
        >{{ $t("msgValidateMinLength1") }} {{ v.$params.minValue.min | numeral("0,0.00") }}
      </span>
      <span class="text-error" v-else-if="v.maxValue == false"
        >{{ $t("msgValidateMaxLength1") }} {{ v.$params.maxValue.max | numeral("0,0.00") }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    textFloat: {
      required: true,
      type: String
    },
    required: {
      required: false,
      type: Boolean
    },
    detail: {
      required: false,
      type: String
    },
    name: {
      required: false,
      type: String
    },
    id: {
      required: false,
      type: [String ,Number]
    },
    value: {
      required: false,
      type: [String ,Number]
    },
    isDisplay: {
      required: false,
      type: Boolean
    },
    isRequired: {
      required: false,
      type: Boolean
    },
    isValidate: {
      required: false,
      type: Boolean
    },
    v: {
      required: false,
      type: Object
    },
    className: {
      required: false,
      type: String
    },
    disabled: {
      required: false,
      type: Boolean
    },
    textGuide: {
      required: false,
      type: String
    },
    min: {
      required: false,
      type: [String ,Number]
    },
    max: {
      required: false,
      type: [String ,Number],
    },
    step: {
      required: false,
      type: [String ,Number],
    },
  },
  computed: {
    val(){
      let value = this.value.toString().replace(/[^\d.-]+/g, '');
      if(value) value = parseFloat(value).toLocaleString();

      return value
    },
    background () {
      let min = 0;
      let max = this.max > 5000000 ? 5000000 : this.max
      max = max - this.min;
      let value = this.value - this.min;
      value = value / max * 100
      let background = `background:linear-gradient(to right, #F9B526 ${min}%, #FF6439 ${value}%, #BC540E ${value}%, #BC540E 100%)`;
      if(this.isDisabled) background = `background:linear-gradient(to right, #b7b7b7 ${min}%, #959595 ${value}%, #BC540E ${value}%, #BC540E 100%)`;
      return background 
    },
  },
  methods: {
    async handleInput(val) {
      let value = val.toString().replace(/[^\d.-]+/g, '');
      await this.$emit("input", value);
    },
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
  }
};
</script>

<style lang="scss" scoped>
.div-input {
  margin-bottom: 0px;
  position: relative;
  white-space: nowrap;
}
.input-custom {
  padding: 0px;
  > label {
    color: #22201F;
    font-size: 28px;
    text-align: center;
    width: 100%;
  }
  .input-custom > input:focus {
    border: 1px solid #F9B526;
  }
  .error > input {
    border-color: red !important;
  }
  .wrap-progress {
    background-color: #FFD542;
    width: 100%;
    padding: 30px 15px;
    border-radius: 20px;
    border: 3px solid #FF6439;
  }
  .progress {
    background: linear-gradient(to right, #F9B526 0%, #FF6439 0%, #F3F3F3 0%, #F3F3F3 100%);
    border-radius: 8px;
    height: 4px;
    width: 100%;
    outline: none;
    transition: background 450ms ease-in;
    -webkit-appearance: none;
    overflow: unset;
  }

  .progress::-webkit-slider-thumb {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    -webkit-appearance: none;
    cursor: ew-resize;
    background: #FF6439;
  }
  .custom-input {
    display: block;
    width: 75% !important;
    color: #FF6439;
    border: 1px solid #E1E1E1;
    border-radius: 6px;
    padding: 7px;
    font-size: 50px;
    border-radius: 3px;
    text-align: center;
  }
  .img-bar {
    width: 120px;
  }
}
.text-desc {
  color: #9b9b9b;
  font-size: 12px;
}
.display-only {
  position: absolute;
  z-index: 5000;
  width: 100%;
  height: 100%;
  border-radius: 10px;
}
.text-guide {
  color: #ff0000;
  font-size: 18px;
  margin-bottom: 0;
  text-align: center;
}
.text-error {
  color: #ff0000;
  font-size: 14px;
  white-space: normal !important;
}
.d-grid {
  display: grid;
}
.button-eye {
  position: absolute;
  right: 20px;
  top: 50%;
  z-index: 6;
}
.text-warning-message {
  color: #bebebe;
  font-size: 12px;
}
@media (max-width: 767.98px) {
  .input-custom > label {
    font-size: 22px;
  }
}
</style>
