<template>
  <div class="position-relative">
    <b-progress
      height="2.75rem"
      variant="main"
      :class="['mb-3 result', result]"
      :value="value"
      :max="max"
    />
    <img src="@/assets/images/Goal.png" class="img-goal" :style="`left: calc(${calValue}% - 12px)`" />
    <div class="line-for-goal" :style="`left: calc(${calValue}% - 2px)`"></div>
    <img src="@/assets/images/Coin.png" class="img-coin" />
    <div class="line-for-coin"></div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      required: false,
      type: [String ,Number]
    },
    max: {
      required: false,
      type: [String ,Number],
    },
    result: {
      required: false,
      type: [String ],
    },
  },
  computed: {
    calValue() {
      let num = 100 / this.max;
      return num * this.value; 
    }
  },
  methods: {
    
  }
};
</script>

<style lang="scss" scoped>
.result.progress {
  background-color: #00C800 !important;
  border: 3px solid #FAB728 !important;
}
.result.progress.negative {
  background-color: #EBEBEB !important;
}
::v-deep .result {
  .progress-bar.bg-main {
    background-color: #FF761A !important;
  }
}
.img-goal,
.img-coin {
  position: absolute;
  height: 27px;
  top: -38px;
}
.img-coin {
  right: -12px;
}
.line-for-coin,
.line-for-goal {
  position: absolute;
  border: 2px solid #FAB728 !important;
  background-color: #FF761A !important;
  top: -4px;
  height: 3.25rem;
  width: 2px;
}
.line-for-coin {
  width: 7px;
  border-radius: 5px;
  right: -3px;
}
</style>
