<template>
  <div :class="['div-input', className]">
    <div class="display-only" v-if="isDisplay"></div>
    <div :class="['input-custom', { error: isValidate }]">
      <div class="d-flex justify-content-between">
        <label v-if="textFloat" :class="{disabled: isDisabled}">
          {{ textFloat }}
          <span v-if="isRequired" class="text-danger">*</span>
        </label>
        <div class="text-right d-flex flex-column">
          <b-button v-if="isDisabled" variant="link" class="btn-edit text-right" @click="isDisabled = !isDisabled">แก้ไข</b-button>
          <span v-if="textGuide" class="text-error mb-0">{{ textGuide }}</span>
        </div>
      </div>
      <b-row>
        <b-col cols="8" md="9" class="d-flex align-items-center">
          <input
            :class="['progress']"
            type="range"
            :name="name"
            :id="id"
            :value="value || 0"
            :disabled="isDisabled"
            :min="min"
            :max="max"
            :step="step"
            @input="$emit('input', $event.target.value)"
            :style="background"
          />
        </b-col>
        <b-col cols="4" md="3" :class="['pl-0', 'w-100 position-relative', {disabled: isDisabled}]">
          <b-input-group>
            <template #append>
              <b-input-group-text>%</b-input-group-text>
            </template>
            <input
              :class="['custom-input']"
              :name="name"
              :required="required"
              :value="value"
              size="sm"
              @input="$emit('input', $event.target.value)"
              @change="onDataChange"
              :disabled="isDisabled"
            />
          </b-input-group>
        </b-col>
      </b-row>
    </div>
    <span v-if="detail" class="text-desc text-wrap">{{ detail }}</span>
    <div v-if="v && v.$error" class="text-right">
      <span class="text-error" v-if="v.required == false">{{
        $t("msgValidateRequired")
      }}</span>
      <span class="text-error" v-else-if="v.integer == false">{{
        $t("msgValidateInteger")
      }}</span>

      <span class="text-error" v-else-if="v.decimal == false">{{
        $t("msgValidateInteger")
      }}</span>
      <span class="text-error" v-else-if="v.minValue == false"
        >{{ $t("msgValidateMinLength1") }} {{ v.$params.minValue.min }}%
      </span>
      <span class="text-error" v-else-if="v.maxValue == false"
        >{{ $t("msgValidateMaxLength1") }} {{ v.$params.maxValue.max }}%
      </span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    textFloat: {
      required: true,
      type: String
    },
    required: {
      required: false,
      type: Boolean
    },
    detail: {
      required: false,
      type: String
    },
    name: {
      required: false,
      type: String
    },
    id: {
      required: false,
      type: [String ,Number]
    },
    value: {
      required: false,
      type: [String ,Number]
    },
    step: {
      required: false,
      type: [String ,Number]
    },
    isDisplay: {
      required: false,
      type: Boolean
    },
    isRequired: {
      required: false,
      type: Boolean
    },
    isValidate: {
      required: false,
      type: Boolean
    },
    v: {
      required: false,
      type: Object
    },
    className: {
      required: false,
      type: String
    },
    disabled: {
      required: false,
      type: Boolean
    },
    textGuide: {
      required: false,
      type: String
    },
    min: {
      required: false,
      type: [String ,Number]
    },
    max: {
      required: false,
      type: [String ,Number],
    },
  },
  data() {
    return {
      isDisabled: true
    };
  },
  computed: {
    background () {
      let min = 0;
      let max = this.max - this.min;
      let value = this.value - this.min;
      value = value / max * 100
      let background = `background:linear-gradient(to right, #F9B526 ${min}%, #FF6439 ${value}%, #F3F3F3 ${value}%, #F3F3F3 100%)`;
      if(this.isDisabled) background = `background:linear-gradient(to right, #b7b7b7 ${min}%, #959595 ${value}%, #F3F3F3 ${value}%, #F3F3F3 100%)`;
      return background 
    },
  },
  methods: {
    onDataChange(event) {
      this.$emit("onDataChange", event.target.value);
    },
  }
};
</script>

<style lang="scss" scoped>
.div-input {
  margin-bottom: 15px;
  position: relative;
  white-space: nowrap;
}
.input-custom {
  padding: 0px;
  > div > {
      label {
        font-size: 16px;
        color: #707070;
        margin-bottom: 10px;
        line-height: 20px;
      }
    }
    label.disabled {
      color: #707070;
    }
  .input-custom > input:focus {
    border: 1px solid #F9B526;
  }
  .error > input {
    border-color: red !important;
  }

  .progress {
    background: linear-gradient(to right, #F9B526 0%, #FF6439 0%, #F3F3F3 0%, #F3F3F3 100%);
    border-radius: 8px;
    height: 4px;
    width: 100%;
    outline: none;
    transition: background 450ms ease-in;
    -webkit-appearance: none;
    overflow: unset;
  }

  .progress::-webkit-slider-thumb {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    -webkit-appearance: none;
    cursor: ew-resize;
    background: #FF6439;
  }
  .custom-input {
    display: block;
    width: calc(100% - 38px);
    color: #FF6439;
    border: 1px solid #E1E1E1;
    border-radius: 6px;
    padding: 7px;
    font-size: 16px;
    border-radius: 3px;
    text-align: center;
  }
  .input-group-text {
    color: #FF6439;
    background-color: #f5f5f5;
  }
}
.text-desc {
  color: #9b9b9b;
  font-size: 12px;
}
.display-only {
  position: absolute;
  z-index: 5000;
  width: 100%;
  height: 100%;
  border-radius: 10px;
}
.text-error {
  color: #ff0000;
  font-size: 14px;
  white-space: normal !important;
  width: 100%;
}
.d-grid {
  display: grid;
}
.button-eye {
  position: absolute;
  right: 20px;
  top: 50%;
  z-index: 6;
}
.hastextFloat {
  top: 2.2rem;
}
.text-warning-message {
  color: #bebebe;
  font-size: 12px;
}
.btn-edit {
  color: #FF761A !important;
  border: 0 !important;
  padding: 0 !important;
  text-decoration: underline;
  font-size: 16px;
}
.submit-button>.btn-edit:active, .btn-edit:focus {
  background-color: white !important;
}
.disabled .input-group {
  .input-group-text,
  input:disabled {
    background-color: #efece9 !important;
    color: #707070 !important;
  }
}
@media (max-width: 767.98px) {
  .input-custom {
    > div > label {
      font-size: 15px;
    }
  } 
}
</style>
